export default {
    tableFields: [
      {
        name: '__component:badge-column',
        title: '',
        dataClass: 'text-center',
       // width: '4%'
      },
      {
        name: 'battery',
        title: 'Bateria',
        sortField: 'battery',
        //width: '34%'
      } , 
      {
        name: 'location',
        title: 'Ubicación GPS',
        sortField: 'localization',
      //  width: '34%'
      },    
      {
        name: 'last_connection',
        title: 'Ultima Conexión',
        sortField: 'last_connection',
      //  width: '34%'
      },
     
      {
        name:       '__slot:status',
        title:      'Estatus',
        titleClass: "center aligned",
        dataClass:  "center aligned",       
    }, 
     
   
  
    ],
    sortFunctions: {
      'name': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      },
      'location': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      }
    }
  }
  